import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material"
import { useState } from "react";

//Componente de inserção de senha
// eslint-disable-next-line react/prop-types
export function InputPassword({ defaulPasswd, setPasswd }) {
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    return (
        <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={defaulPasswd ? defaulPasswd : ''}
                onChange={setPasswd}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

// eslint-disable-next-line react/prop-types
export const InputNumber = ({ defaultLabel, defaulValue, setNumber }) => {
    return (
        <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
                label={defaultLabel}
                type="number"
                variant="outlined"
                defaultValue={defaulValue}
                onChange={setNumber}
            />
        </FormControl>
    )
}

// eslint-disable-next-line react/prop-types
export const InputText = ({ defaulValue, defaultLabel, type = 'text', setText }) => {
    return (
        <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
                variant="outlined"
                type={type}
                label={defaultLabel}
                defaultValue={defaulValue}
                onChange={setText}
            />
        </FormControl>
    )
}
import React from 'react'
import ReactDOM from 'react-dom/client'
import { FunctionsProvider } from './Context/functionsContext.jsx'
import { SnackbarProvider } from 'notistack'
import MainRoutes from './routes.jsx'
import { createTheme, ThemeProvider } from '@mui/material'
import { orange } from '@mui/material/colors'

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: "#f10b5c"
    }
  }
});

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={1}>
      <FunctionsProvider>
        <MainRoutes />
      </FunctionsProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  // </React.StrictMode>,
)

import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useContext } from "react";
import DoneIcon from '@mui/icons-material/Done';
import { FunctionsContext } from "../../Context/functionsContext";

export function BodyPage() {
    const {
        numeroComanda,
        setNumeroComanda,
        situacao,
        setSituacao,
        confirmarComanda,
        conectado,
        palette
    } = useContext(FunctionsContext)

    return (
        <Box>
            <Box textAlign="center">
                <Typography variant="h4">
                    Alterar situação da comanda
                </Typography>
            </Box>
            <Box alignItems="center" justifyContent="center" gap={4} paddingTop={2}>
                <Grid container spacing={2} direction="column">
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={numeroComanda}
                            variant="outlined"
                            label="Número da comanda"
                            onChange={(e) => setNumeroComanda(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Escolha a nova situação da comanda</InputLabel>
                            <Select
                                value={situacao}
                                label="Escolha a nova situação da comanda"
                                onChange={(e) => setSituacao(e.target.value)}>
                                <MenuItem value="L">Comanda livre</MenuItem>
                                <MenuItem value="C">Comanda com consumo</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={0} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontWeight="bold" color={conectado ? palette.success.main : palette.error.main}>
                            {conectado ? "Conectado" : "Desconectado"}
                        </Typography>

                        <Button onClick={() => confirmarComanda()} variant="contained" endIcon={<DoneIcon />}>
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
import { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { InputText } from '../../Components/inputComponents'
import { FunctionsContext } from '../../Context/functionsContext'
import DoneIcon from '@mui/icons-material/Done'
import { useSnackbar } from 'notistack'
import AuthService from '../../Util/AuthService'
import { AdminPassword } from '../../Components/AdminPassword'
import { ModificationAlert } from '../../Components/ModificationAlert'

export function ConfgOnline() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar()
    const { changeBuscaOnline, onlineConfig, setOnlineConfig, resetOfflineConfig } = useContext(FunctionsContext)
    const [show, setShow] = useState(false)
    const [logged, setLogged] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!logged) return

        changeBuscaOnline()
    }, [logged])

    function login(e) {
        e.preventDefault()

        setLoading(true)

        return service.login(onlineConfig).then(profile => {
            resetOfflineConfig()
            setOnlineConfig({ ...onlineConfig, profile })
            localStorage.setItem(service.getOnlineConfigStorageKey(), JSON.stringify({ ...onlineConfig, profile }))
            setLogged(true)

            enqueueSnackbar('Logado com sucesso', { variant: 'success' })
        }).catch(err => {
            console.log(err)
            enqueueSnackbar(err.message, { variant: 'error' })
        }).finally(() => setLoading(false))
    }

    if (!show) {
        return (
            <Box>
                <Typography variant="h4" textAlign="center" mb={2}>
                    Configurações online
                </Typography>
                <AdminPassword onAuthenticate={() => setShow(true)} />
            </Box>
        )
    }

    return (
        <form onSubmit={login}>
            <Typography variant="h4" textAlign="center">
                Configurações online
            </Typography>
            <ModificationAlert storageKey={service.getOfflineConfigStorageKey()} />
            <Box paddingTop={2}>
                <Grid container spacing={1} direction="column">
                    <Grid item xs={2}>
                        <InputText
                            defaultLabel="Usuário"
                            defaulValue={onlineConfig.username}
                            setText={(e) => setOnlineConfig(state => ({ ...state, username: e.target.value }))} />
                    </Grid>
                    <Grid item xs={2}>
                        <InputText
                            type="password"
                            defaultLabel="Senha"
                            defaulValue={onlineConfig.password}
                            setText={(e) => setOnlineConfig(state => ({ ...state, password: e.target.value }))} />
                    </Grid>
                    <Grid item xs={2}>
                        <InputText
                            defaultLabel="Diretório para salvar as comandas"
                            defaulValue={onlineConfig.path}
                            setText={(e) => setOnlineConfig(state => ({ ...state, path: e.target.value }))}
                        />
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="end">
                        <Button
                            type="submit"
                            disabled={loading}
                            variant="contained"
                            onClick={login}
                            startIcon={<DoneIcon />}>
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}
import HomeIcon from '@mui/icons-material/Home'
import WifiIcon from '@mui/icons-material/Wifi'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import InfoIcon from '@mui/icons-material/Info'
import { Grid, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
export const MenuItens = ({ anchorEl, handleClose }) => {
    const theme = useTheme()

    return (
        <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}>

            <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                <MenuItem onClick={handleClose}>
                    <Grid container justifyContent="start" spacing={2}>
                        <Grid item>
                            <HomeIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                            <Typography> Página Principal </Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Link>

            <Link to="/ConfgOnline" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                <MenuItem onClick={handleClose}>
                    <Grid container justifyContent="start" spacing={2}>
                        <Grid item>
                            <WifiIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                            <Typography> Configuração Online </Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Link>

            <Link to="/ConfgOffline" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                <MenuItem onClick={handleClose}>
                    <Grid container justifyContent="start" spacing={2}>
                        <Grid item>
                            <WifiOffIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                            <Typography> Configuração Offline </Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Link>

            <Link to="/About" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                <MenuItem onClick={handleClose}>
                    <Grid container justifyContent="start" spacing={2}>
                        <Grid item>
                            <InfoIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                            <Typography>Sobre</Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Link>
        </Menu>
    )
}
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import App from "./App";
import { BodyPage } from "./Pages/MainPage/bodyPage";
import { ConfgOffline } from "./Pages/ConfgOffline/confgOffline";
import { About } from "./Pages/About/About";
import { ConfgOnline } from "./Pages/ConfgOnline/confgOnline";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/home",
        element: <BodyPage />
      },

      {
        path: "/ConfgOnline",
        element: <ConfgOnline />
      },

      {
        path: "/ConfgOffline",
        element: <ConfgOffline />
      },

      {
        path: "/About",
        element: <About />
      },

      {
        index: true,
        element: <Navigate to="/home" replace /> // Redireciona para /home
      },
    ]
  },
])

export default function MainRoutes() {
  return (
    <RouterProvider router={router} />
  )
}
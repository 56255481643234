import { Box, Button, Grid, Typography } from "@mui/material"
import { FunctionsContext } from "../../Context/functionsContext"
import { useContext, useState } from "react"
import DoneIcon from '@mui/icons-material/Done'
import { InputNumber, InputPassword, InputText } from "../../Components/inputComponents";
import { AdminPassword } from "../../Components/AdminPassword";
import AuthService from "../../Util/AuthService";
import { ModificationAlert } from "../../Components/ModificationAlert";

export function ConfgOffline() {
    const service = new AuthService()
    const { changeBusca, config, setConfig, resetOnlineConfig } = useContext(FunctionsContext)
    const [show, setShow] = useState(false)

    function handleConfirm(e) {
        e.preventDefault()

        resetOnlineConfig()
        localStorage.setItem(service.getOfflineConfigStorageKey(), JSON.stringify(config))
        changeBusca()
    }

    if (!show) {
        return (
            <Box>
                <Typography variant="h4" textAlign="center" mb={2}>
                    Configurações offline
                </Typography>
                <AdminPassword onAuthenticate={() => setShow(true)} />
            </Box>
        )
    }

    return (
        <form onSubmit={handleConfirm}>
            <Typography variant="h4" textAlign="center">
                Configurações offline
            </Typography>
            <ModificationAlert storageKey={service.getOnlineConfigStorageKey()} />
            <Box paddingTop={2}>
                <Grid container spacing={1} direction="column">
                    <Grid item xs={2}>
                        <InputText
                            defaultLabel="Endereço do banco de dados"
                            defaulValue={config.host}
                            setText={(e) => setConfig({ ...config, host: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputText
                            defaultLabel="Usuário do banco de dados"
                            defaulValue={config.user}
                            setText={(e) => setConfig({ ...config, user: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputPassword
                            defaulPasswd={config.password}
                            setPasswd={(e) => setConfig({ ...config, password: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputText
                            defaultLabel="Nome do banco de dados"
                            defaulValue={config.database}
                            setText={(e) => setConfig({ ...config, database: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputNumber
                            defaultLabel="Porta do banco de dados"
                            defaulValue={config.port}
                            setNumber={(e) => setConfig({ ...config, port: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputText
                            defaultLabel="Diretório para salvar as comandas"
                            defaulValue={config.path}
                            setText={(e) => setConfig({ ...config, path: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputNumber
                            defaultLabel="Intevalo do pooling"
                            defaulValue={config.time}
                            setNumber={(e) => setConfig({ ...config, time: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={0} display="flex" justifyContent="end">
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={handleConfirm}
                        startIcon={<DoneIcon />}>
                        Confirmar
                    </Button>
                </Grid>
            </Box>
        </form>
    )
}
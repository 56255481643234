import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import DoneIcon from '@mui/icons-material/Done'

export function AdminPassword({ onAuthenticate }) {
    const [error, setError] = useState('')
    const [password, setPassword] = useState('')

    function onChange(e) {
        setError('')
        setPassword(e.target.value)
    }

    function handleConfirm(e) {
        e.preventDefault()

        if (password === 'balanca123') {
            onAuthenticate()
        } else {
            setError('Senha inválida')
        }
    }

    return (
        <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleConfirm}>
            <TextField
                sx={{ mb: 1 }}
                type="password"
                error={!!error}
                helperText={error}
                defaultValue={password}
                label="Senha de administrador"
                onChange={onChange} />
            <Button
                type="submit"
                variant="contained"
                onClick={handleConfirm}
                startIcon={<DoneIcon />}>
                Confirmar
            </Button>
        </form>
    )
}
import { AppBar, Avatar, Grid, IconButton, styled, Toolbar, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { FunctionsContext } from "../../Context/functionsContext"
import MenuIcon from '@mui/icons-material/Menu'
import { MenuItens } from "../../Components/menu"

const PREFIX = 'controle-comandas'

const classes = {
    root: `${PREFIX}-root`,
    header: `${PREFIX}-header`,
    body: `${PREFIX}-body`,
    title: `${PREFIX}-title`
}

const Root = styled('div')(() => ({
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },

    [`& .${classes.title}`]: {
        flexGrow: 1
    }
}))

export function Header() {
    const { palette } = useContext(FunctionsContext)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Root className={classes.root}>
            <AppBar position="static" style={{ background: palette.primary.main }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleClick}>
                        <MenuIcon />
                    </IconButton >
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Avatar sx={{ width: 56, height: 56 }} src="../assets/logo-sischef.png" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" fontWeight="bold" color="#FFF" className={classes.title}>
                                Catraca Sischef
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <MenuItens
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </Root>
    )
}
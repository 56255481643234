import { Alert, AlertTitle } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FunctionsContext } from '../Context/functionsContext'

export function ModificationAlert({ storageKey }) {
    const [show, setShow] = useState(false)
    const { config, onlineConfig } = useContext(FunctionsContext)

    useEffect(() => {
        const config = localStorage.getItem(storageKey)

        setShow(!!config)
    }, [storageKey, config, onlineConfig])

    if (!show) {
        return null
    }

    return (
        <Alert severity="warning" sx={{ mt: 1 }}>
            <AlertTitle>Foi identificado que há outra configuração hábilitada</AlertTitle>
            Ao confirmar esta configuração você irá remover a configuração atual
        </Alert>
    )
}
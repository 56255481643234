import { Box, Typography } from "@mui/material"

import { FunctionsContext } from "../../Context/functionsContext"
import { useContext } from "react"

export const About = () => {
    const { version } = useContext(FunctionsContext)

    return (
        <Box>
            <Box textAlign="center">
                <Typography variant="h4">
                    Sobre
                </Typography>
            </Box>
            <Box pt={2}>
                <Typography>
                    <strong>Nome:</strong> {version.appName} - {version.appDescription}
                </Typography>
                <Typography>
                    <strong>Versão:</strong> {version.appVersion}
                </Typography>

                <Box textAlign="center" pt={4}>
                    <Typography variant="caption">
                        Desenvolvido por Parseint Desenvolvimento de Sistemas
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}